import React from 'react';
import { string, shape, node, arrayOf } from 'prop-types';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const propTypes = {
  featuredImage: shape({}).isRequired,
  imgAlt: string.isRequired,
  name: string.isRequired,
  description: node.isRequired,
  challengeTitle: string.isRequired,
  challengeText: node.isRequired,
  solutionTitle: string.isRequired,
  solutionText: node.isRequired,
  footerDescription: node,
  link: string,
  linkLabel: string,
  links: arrayOf(shape({ label: string.isRequired, to: string.isRequired })),
};

const defaultProps = {
  footerDescription: null,
  link: null,
  linkLabel: null,
  links: null,
};

const PortfolioCard = ({
  imgAlt,
  name,
  featuredImage,
  description,
  challengeTitle,
  challengeText,
  solutionTitle,
  solutionText,
  footerDescription,
  link,
  linkLabel,
  links,
}) => {
  return (
    <Row className="flex-column">
      <Col>
        <h3 className="ui-porfolio-work--title mb-4">{name}</h3>
        <div className="mb-4 font-size-sm lh-lg">{description}</div>
      </Col>
      <Col>
        <Row>
          <Col
            lg={5}
            xl={4}
            className="d-flex align-items-center pe-4 mb-4 mb-md-5 mb-lg-0"
          >
            <GatsbyImage image={getImage(featuredImage)} alt={imgAlt} />
          </Col>
          <Col
            lg={7}
            xl={8}
            className="d-flex flex-column justify-content-center gap-4"
          >
            <div className="ui-porfolio-work--box d-flex align-items-start border border-accent rounded p-3">
              <StaticImage
                src="../../images/site-core/icons/calendar-week.svg"
                className="ui-porfolio-work--icon me-2"
                alt="Challenge Icon"
              />
              <div className="pt-1">
                <h6 className="font-family-base mb-2">{challengeTitle}</h6>
                <div className="font-size-sm lh-lg">{challengeText}</div>
              </div>
            </div>
            <div className="ui-porfolio-work--box d-flex align-items-start border border-success rounded p-3">
              <StaticImage
                src="../../images/site-core/icons/check.svg"
                className="ui-porfolio-work--icon me-2"
                alt="Solution Icon"
              />
              <div className="pt-1">
                <h6 className="font-family-base mb-2">{solutionTitle}</h6>
                <div className="font-size-sm lh-lg">{solutionText}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      {footerDescription && (
        <Col>
          <div className="mt-4 font-size-sm lh-lg">{footerDescription}</div>
        </Col>
      )}
      <Col className="d-flex flex-column flex-md-row align-items-center gap-4 mt-5">
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="btn btn-link ui-porfolio-work--btn"
          >
            {linkLabel}
          </a>
        )}
        {links && (
          <ul className="ui-porfolio-work--category m-0 p-0 d-flex flex-wrap align-items-center justify-content-center unstyled">
            <li className="d-flex">
              <StaticImage
                src="../../images/site-core/icons/bxs-tag-alt.svg"
                className="me-2"
                alt="Portfolio Category"
              />
            </li>
            {links.map(({ label, to }) => {
              return (
                <li key={to}>
                  <Link
                    to={to}
                    className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                  >
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </Col>
    </Row>
  );
};

PortfolioCard.propTypes = propTypes;
PortfolioCard.defaultProps = defaultProps;

export default PortfolioCard;
